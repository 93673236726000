body{
    background-color: #000000;
}
.form-control{
    font-size: 1.3em;
}
label{
    font-size:x-large;
    padding-bottom: 3px;
}
#login-button{
    font-size: 1.4em;
}
@media only screen and (max-width: 990px) {
    h1,p,a,label{
        color: #ffffff !important;
    }
    input{
        background-color: #ffffff;
        color: #707070;
    }
    #login-con{
        background-color: #000000;
    }
}
@media only screen and (min-width: 990px) {
    h1,p,a,label{
        color: #000000 !important;
    }
    #login-con{
        background-color: #ffffff;
    }
}